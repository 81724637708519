import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M1415 4054 c-142 -20 -191 -29 -265 -49 -420 -117 -775 -448 -924
-863 -96 -267 -108 -544 -37 -823 61 -241 187 -462 365 -642 121 -124 259
-205 421 -248 108 -29 325 -32 435 -5 213 50 394 167 525 337 45 57 115 178
115 196 0 6 -35 -10 -79 -36 -137 -83 -284 -120 -472 -121 -335 0 -653 223
-782 550 -67 169 -76 403 -22 570 62 194 167 343 326 460 80 60 165 101 284
136 122 35 308 38 430 5 222 -59 415 -201 528 -388 l32 -52 278 -1 c174 0 277
4 277 10 0 17 -46 133 -80 200 -194 386 -577 672 -999 745 -87 16 -301 27
-356 19z"/>
<path d="M830 2971 c-9 -17 7 -63 95 -278 59 -142 113 -268 121 -280 11 -18
24 -23 54 -23 33 0 42 5 54 28 40 77 216 519 214 536 -5 29 -34 44 -52 26 -12
-12 -143 -321 -208 -490 -8 -22 -21 4 -109 220 -54 135 -103 252 -107 260 -14
24 -49 24 -62 1z"/>
<path d="M1421 2976 c-8 -9 -11 -96 -9 -297 l3 -284 27 -3 c45 -5 49 23 46
315 -3 221 -5 265 -18 273 -21 13 -36 12 -49 -4z"/>
<path d="M3137 2978 c-22 -18 -239 -533 -235 -561 2 -17 10 -22 32 -22 27 0
32 6 60 73 l30 72 146 0 146 0 30 -72 c28 -67 33 -73 60 -73 23 0 30 5 32 23
4 28 -212 542 -235 560 -9 6 -24 12 -33 12 -9 0 -24 -6 -33 -12z m91 -226 c29
-70 52 -130 52 -134 0 -5 -49 -8 -110 -8 -60 0 -110 3 -110 8 0 12 105 262
110 262 3 0 29 -58 58 -128z"/>
<path d="M4152 2973 c-10 -10 -70 -138 -132 -286 -118 -282 -122 -297 -65
-297 18 0 28 14 55 75 l33 75 146 0 146 0 30 -73 c28 -67 32 -72 60 -72 22 0
31 5 33 20 3 23 -207 528 -232 557 -20 23 -48 23 -74 1z m97 -230 c28 -68 51
-125 51 -128 0 -3 -49 -5 -110 -5 -69 0 -110 4 -110 10 0 24 104 260 111 253
4 -4 30 -63 58 -130z"/>
<path d="M1592 2968 c-17 -17 -17 -549 0 -566 17 -17 55 -15 62 4 3 9 6 58 6
109 l0 93 128 4 c143 4 173 14 218 73 47 62 45 168 -3 226 -49 57 -90 69 -253
69 -101 0 -150 -4 -158 -12z m302 -72 c44 -18 66 -52 66 -101 0 -86 -51 -115
-200 -115 l-100 0 0 115 0 115 100 0 c63 0 113 -5 134 -14z"/>
<path d="M2375 2967 c-3 -7 -4 -138 -3 -292 l3 -280 124 -3 c165 -4 215 11
286 81 62 63 87 127 86 223 -1 116 -62 212 -166 260 -45 21 -64 24 -188 24
-102 0 -139 -3 -142 -13z m295 -78 c56 -25 77 -44 102 -92 48 -95 32 -197 -41
-268 -56 -54 -103 -69 -215 -69 l-76 0 0 225 0 225 93 0 c71 0 102 -5 137 -21z"/>
<path d="M3435 2960 c-13 -40 10 -50 111 -50 l94 0 0 -244 c0 -134 3 -251 6
-260 7 -19 45 -21 62 -4 9 9 12 80 12 260 l0 248 94 0 c101 0 124 10 111 50
-6 19 -13 20 -245 20 -232 0 -239 -1 -245 -20z"/>
<path d="M2065 2639 c-12 -36 18 -49 110 -49 88 0 120 13 113 46 -3 17 -14 19
-110 22 -102 3 -107 2 -113 -19z"/>
<path d="M4579 2611 c-20 -20 -29 -39 -29 -61 0 -43 47 -90 90 -90 43 0 90 47
90 90 0 22 -9 41 -29 61 -20 20 -39 29 -61 29 -22 0 -41 -9 -61 -29z"/>
<path d="M2382 2193 c-66 -340 -227 -641 -433 -813 -33 -28 -58 -50 -56 -50
22 0 196 69 267 106 269 139 478 350 615 624 31 62 95 227 95 245 0 3 -105 5
-232 5 l-233 0 -23 -117z"/>
<path d="M3145 2185 c-22 -8 -41 -15 -42 -15 -1 0 -10 -14 -18 -31 -21 -39
-12 -82 22 -109 23 -18 47 -26 126 -44 18 -4 27 -13 27 -26 0 -29 -74 -29 -92
0 -10 15 -24 20 -55 20 -37 0 -43 -3 -43 -21 0 -57 53 -89 150 -89 98 0 142
34 142 108 0 47 -33 76 -102 91 -72 16 -94 29 -79 47 15 19 68 18 75 -1 4 -10
21 -15 55 -15 l48 0 -11 30 c-19 56 -124 85 -203 55z"/>
<path d="M3500 2083 c0 -127 15 -168 70 -195 53 -27 148 -24 191 5 51 34 59
59 59 185 l0 112 -50 0 -50 0 0 -108 c0 -120 -10 -142 -63 -142 -45 0 -57 32
-57 147 l0 103 -50 0 -50 0 0 -107z"/>
<path d="M3980 2030 l0 -160 109 0 c154 0 190 17 191 92 0 28 -6 41 -27 58
l-27 21 22 15 c33 23 31 86 -4 113 -24 19 -40 21 -145 21 l-119 0 0 -160z
m174 94 c9 -3 16 -17 16 -30 0 -22 -4 -24 -50 -24 -46 0 -50 2 -50 23 0 13 3
27 7 30 8 9 56 9 77 1z m24 -151 c4 -25 -25 -37 -74 -31 -23 2 -30 9 -32 31
-3 28 -3 28 50 25 46 -3 53 -6 56 -25z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
